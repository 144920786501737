import React from "react";

const Footer = () => {
  return (
    <div className="fixed bottom-0 w-full bg-white shadow-lg">
      <div className="text-center p-4">
        <p className="text-gray-600">© 本网站老彭所有</p>
      </div>
    </div>
  );
};

export default Footer;
