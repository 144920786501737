import React from 'react';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

const MyCodes = () => {
  const navigate = useNavigate();
  
  // 模拟的代码列表数据
  const codesList = [
    { id: 1, name: '测试代码1', language: 'JavaScript', createTime: '2024-03-20' },
    { id: 2, name: '测试代码2', language: 'Python', createTime: '2024-03-21' },
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-2xl font-bold">我的代码</h1>
          <button
            onClick={() => navigate('/')}
            className="border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0"
          >
            返回编辑器
          </button>
        </div>
        <div className="bg-white rounded-lg shadow">
          <table className="min-w-full">
            <thead>
              <tr className="border-b">
                <th className="px-6 py-3 text-left">文件名</th>
                <th className="px-6 py-3 text-left">语言</th>
                <th className="px-6 py-3 text-left">创建时间</th>
                <th className="px-6 py-3 text-left">操作</th>
              </tr>
            </thead>
            <tbody>
              {codesList.map((code) => (
                <tr key={code.id} className="border-b hover:bg-gray-50">
                  <td className="px-6 py-4">{code.name}</td>
                  <td className="px-6 py-4">{code.language}</td>
                  <td className="px-6 py-4">{code.createTime}</td>
                  <td className="px-6 py-4">
                    <button
                      onClick={() => navigate('/')}
                      className="text-blue-600 hover:text-blue-800 mr-4"
                    >
                      编辑
                    </button>
                    <button
                      onClick={() => console.log('删除', code.id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      删除
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default MyCodes; 