import React from "react";

const OutputDetails = ({ outputDetails }) => {
  return (
    <div className="metrics-container flex items-center space-x-3">
      <div className="text-xs">
        <span className="font-semibold px-2 py-1 rounded-md bg-gray-100">
          {outputDetails?.status?.description}
        </span>
      </div>
      <div className="text-xs">
        <span className="font-semibold px-2 py-1 rounded-md bg-gray-100">
          {outputDetails?.memory} KB
        </span>
      </div>
      <div className="text-xs">
        <span className="font-semibold px-2 py-1 rounded-md bg-gray-100">
          {outputDetails?.time} s
        </span>
      </div>
    </div>
  );
};

export default OutputDetails;
