import React from "react";

const OutputWindow = ({ outputDetails }) => {
  const formatOutput = (text) => {
    if (!text) return "";
    // 处理换行符，确保正确显示
    return text.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index !== text.split('\n').length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <div className="w-full h-56 bg-[#1e293b] rounded-md text-white font-normal text-sm overflow-y-auto">
      <div className="px-2 py-1 whitespace-pre-wrap">
        {outputDetails && (
          <>
            {formatOutput(outputDetails.stdout)}
            {formatOutput(outputDetails.stderr)}
            {formatOutput(outputDetails.compile_output)}
          </>
        )}
      </div>
    </div>
  );
};

export default OutputWindow;
