import React from "react";
import Select from "react-select";

const customStyles = {
  control: (styles) => ({
    ...styles,
    width: "200px",
    fontSize: "0.875rem",
    borderRadius: "5px",
    border: "2px solid black",
    boxShadow: "5px 5px 0px 0px rgba(0,0,0)",
    "&:hover": {
      border: "2px solid black",
    },
  }),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      fontSize: "0.875rem",
      backgroundColor: isFocused ? "#f3f4f6" : null,
      color: "black",
      "&:hover": {
        backgroundColor: "#f3f4f6",
      },
    };
  },
  menu: (styles) => ({
    ...styles,
    backgroundColor: "white",
    border: "2px solid black",
    borderRadius: "5px",
    boxShadow: "5px 5px 0px 0px rgba(0,0,0)",
  }),
  singleValue: (styles) => ({
    ...styles,
    color: "black",
  }),
  placeholder: (styles) => ({
    ...styles,
    color: "#6b7280",
  }),
};

const ThemeDropdown = ({ handleThemeChange, theme }) => {
  const themes = [
    { value: "light", label: "Light" },
    { value: "vs-dark", label: "Dark" }
  ];

  return (
    <Select
      placeholder={`选择主题`}
      options={themes}
      value={theme}
      onChange={handleThemeChange}
      styles={customStyles}
      isSearchable={false}
    />
  );
};

export default ThemeDropdown;
