import React, { useEffect, useState } from "react";
import CodeEditorWindow from "./CodeEditorWindow";
import axios from "axios";
import { classnames } from "../utils/general";
import { languageOptions } from "../constants/languageOptions";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { defineTheme } from "../lib/defineTheme";
import useKeyPress from "../hooks/useKeyPress";
import Footer from "./Footer";
import OutputWindow from "./OutputWindow";
import CustomInput from "./CustomInput";
import OutputDetails from "./OutputDetails";
import ThemeDropdown from "./ThemeDropdown";
import LanguagesDropdown from "./LanguagesDropdown";
import Navbar from './Navbar';
import useCodeStore from '../store/codeStore';

const pythonDefault = `# 问题：输出你好世界

print("你好，世界！")
print("Hello, World!")

# 测试中文字符串处理
text = "中文测试"
print(len(text))
print("-".join(text))
`;

const API_URL = process.env.REACT_APP_API_URL || "https://api.flybirdai.com:3000";

const Landing = () => {
  const { code, language, theme, setCode, setLanguage, setTheme } = useCodeStore();
  const [customInput, setCustomInput] = useState("");
  const [outputDetails, setOutputDetails] = useState(null);
  const [processing, setProcessing] = useState(null);

  const enterPress = useKeyPress("Enter");
  const ctrlPress = useKeyPress("Control");

  const onSelectChange = (sl) => {
    console.log("selected Option...", sl);
    setLanguage(sl);
  };

  useEffect(() => {
    if (enterPress && ctrlPress) {
      console.log("enterPress", enterPress);
      console.log("ctrlPress", ctrlPress);
      handleCompile();
    }
  }, [ctrlPress, enterPress]);
  const onChange = (action, data) => {
    switch (action) {
      case "code": {
        setCode(data);
        break;
      }
      default: {
        console.warn("case not handled!", action, data);
      }
    }
  };
  const handleCompile = () => {
    setProcessing(true);
    const formData = {
      language_id: language.id,
      source_code: code,
      stdin: customInput
    };
    const options = {
      method: "POST",
      url: `${API_URL}/submissions`,
      params: { base64_encoded: "false" },
      headers: {
        "Content-Type": "application/json",
      },
      data: formData,
    };

    axios
      .request(options)
      .then(function (response) {
        console.log("res.data", response.data);
        const token = response.data.token;
        checkStatus(token);
      })
      .catch((err) => {
        let error = err.response ? err.response.data : err;
        console.log("catch block...", error);
        setProcessing(false);
        showErrorToast();
      });
  };

  const checkStatus = async (token) => {
    const options = {
      method: "GET",
      url: `${API_URL}/submissions/${token}`,
      params: { base64_encoded: "true" },
      headers: {
        "Content-Type": "application/json",
      },
    };
    try {
      let response = await axios.request(options);
      let statusId = response.data.status?.id;

      if (statusId === 1 || statusId === 2) {
        setTimeout(() => {
          checkStatus(token);
        }, 2000);
        return;
      } else {
        setProcessing(false);
        
        const decodeBase64 = (base64) => {
          const binString = window.atob(base64);
          const bytes = new Uint8Array(binString.length);
          for (let i = 0; i < binString.length; i++) {
            bytes[i] = binString.charCodeAt(i);
          }
          return new TextDecoder().decode(bytes);
        };

        if (response.data.stdout) {
          response.data.stdout = decodeBase64(response.data.stdout);
        }
        if (response.data.stderr) {
          response.data.stderr = decodeBase64(response.data.stderr);
        }
        if (response.data.compile_output) {
          response.data.compile_output = decodeBase64(response.data.compile_output);
        }
        
        setOutputDetails(response.data);
        showSuccessToast(`编译成功！`);
        console.log("response.data", response.data);
        return;
      }
    } catch (err) {
      console.log("err", err);
      setProcessing(false);
      showErrorToast();
    }
  };

  function handleThemeChange(th) {
    const theme = th;
    console.log("theme...", theme);
    setTheme(theme);
  }
  useEffect(() => {
    setTheme({ value: "vs-dark", label: "Dark" });
  }, []);

  const showSuccessToast = (msg) => {
    toast.success(msg || `Compiled Successfully!`, {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };
  const showErrorToast = (msg, timer) => {
    toast.error(msg || `Something went wrong! Please try again.`, {
      position: "top-right",
      autoClose: timer ? timer : 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      <Navbar 
        onSelectChange={onSelectChange}
        handleThemeChange={handleThemeChange}
        theme={theme}
      />
      <div className="h-4 w-full bg-gradient-to-r from-pink-500 via-red-500 to-yellow-500"></div>
      <div className="flex flex-row space-x-4 items-start px-4 py-4">
        <div className="flex flex-col w-full h-full justify-start items-end">
          <CodeEditorWindow
            code={code}
            onChange={onChange}
            language={language?.value}
            theme={theme.value}
          />
        </div>

        <div className="right-container flex flex-shrink-0 w-[30%] flex-col">
          <div className="flex flex-col space-y-4">
            <div className="flex items-center">
              <button
                onClick={handleCompile}
                disabled={!code}
                className={classnames(
                  "border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0",
                  !code ? "opacity-50" : ""
                )}
              >
                {processing ? "Processing..." : "编译并运行"}
              </button>
              {outputDetails && (
                <div className="ml-4">
                  <OutputDetails outputDetails={outputDetails} />
                </div>
              )}
            </div>
            <OutputWindow outputDetails={outputDetails} />
            <CustomInput
              customInput={customInput}
              setCustomInput={setCustomInput}
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Landing;
