import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LanguagesDropdown from './LanguagesDropdown';
import ThemeDropdown from './ThemeDropdown';

const Navbar = ({ onSelectChange, handleThemeChange, theme }) => {
  const [fileName, setFileName] = useState('');
  const navigate = useNavigate();
  const username = "老彭";

  const handleSave = () => {
    if (!fileName.trim()) {
      alert('请输入文件名');
      return;
    }
    console.log('保存文件:', fileName);
  };

  const handleMyCode = () => {
    navigate('/my-codes');
  };

  return (
    <nav className="bg-white shadow-lg">
      <div className="max-w-7xl mx-auto px-4">
        <div className="flex justify-between h-16 items-center">
          {/* 左侧文件名输入和保存按钮 */}
          <div className="flex items-center space-x-4">
            <input
              type="text"
              placeholder="请输入文件名"
              value={fileName}
              onChange={(e) => setFileName(e.target.value)}
              className="border-2 border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:border-blue-500"
            />
            <button
              onClick={handleSave}
              className="border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0"
            >
              保存
            </button>
            <div className="flex items-center space-x-4">
              <LanguagesDropdown onSelectChange={onSelectChange} />
              <ThemeDropdown handleThemeChange={handleThemeChange} theme={theme} />
            </div>
          </div>

          {/* 右侧我的代码按钮和用户名 */}
          <div className="flex items-center space-x-4">
            <button
              onClick={handleMyCode}
              className="border-2 border-black z-10 rounded-md shadow-[5px_5px_0px_0px_rgba(0,0,0)] px-4 py-2 hover:shadow transition duration-200 bg-white flex-shrink-0"
            >
              我的代码
            </button>
            <span className="text-gray-700 font-medium">{username}</span>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar; 