import create from 'zustand';
import { languageOptions } from '../constants/languageOptions';

const pythonDefault = `# 问题：输出你好世界

print("你好，世界！")
print("Hello, World!")

# 测试中文字符串处理
text = "中文测试"
print(len(text))
print("-".join(text))
`;

const useCodeStore = create((set) => ({
  code: pythonDefault,
  language: languageOptions[0],
  theme: { value: "vs-dark", label: "Dark" },
  setCode: (code) => set({ code }),
  setLanguage: (language) => set({ language }),
  setTheme: (theme) => set({ theme }),
}));

export default useCodeStore; 